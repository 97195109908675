/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.1
 * @author [Ian Husting]
 */
import React, { useState } from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// PrimeReact components
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
// Custom components
import { FloatingTextInput } from "components/common";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
// Styling
import "./Style.scss";

const AddressFilterLayout = (props) => {
  const { isPending, handleSearch, intl } = props;
  const {
    ADDRESSES_FILTER_ADDRESS_LABEL,
    DIALOG_SEARCH_BUTTON_LABEL,
    DIALOG_CLEAR_FILTER_LABEL,
    ADDRESSES_HOSPITAL_LABEL,
  } = MESSAGE_KEYS;
  // Setup function state

  const [inputAddress, setAddress] = useState("");
  const [checkedIsInstitution, setCheckedIsInstitution] = useState(false);
  const [checkedIsPeppol, setCheckedIsPeppol] = useState(false);
  /** Reset state variables. */
  let resetState = () => {
    setAddress("");
    setCheckedIsInstitution(false);
    handleSearch({});
  };
  /** Call search function if enter key is pressed. */
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch({
        address: inputAddress,
      });
    }
  };

  let buttonClass = isPending ? "pi pi-spin pi-spinner" : "pi pi-search";

  return (
    <div>
      <div className="flex justify-content-center align-items-center gap-4">
        <FloatingTextInput
          id={ADDRESSES_FILTER_ADDRESS_LABEL}
          value={inputAddress}
          onChange={(event) => setAddress(event.target.value)}
          onKeyDown={handleKeyDown}
          label={intl.formatMessage({ id: ADDRESSES_FILTER_ADDRESS_LABEL })}
          autoFocus
        />
        <div className="flex justify-content-center align-items-center gap-1 mt-3">
          <Checkbox
            inputId={ADDRESSES_HOSPITAL_LABEL}
            onChange={(e) => setCheckedIsInstitution(e.checked)}
            checked={checkedIsInstitution}
          ></Checkbox>
          <label htmlFor={ADDRESSES_HOSPITAL_LABEL}>
            {intl.formatMessage({ id: ADDRESSES_HOSPITAL_LABEL })}
          </label>
        </div>
        <div className="flex justify-content-center align-items-center gap-1 mt-3">
          <div className="bill_filter_peppol">
            <Checkbox
              inputId={"peppol"}
              onChange={(e) => {
                setCheckedIsPeppol(e.checked);
              }}
              checked={checkedIsPeppol}
            ></Checkbox>
            <label htmlFor={"peppol"}>
              <div className="peppol-status-img">
                <img
                  src="assets/layout/images/peppol-icon-alone.png"
                  alt="peppol icon"
                  className="peppol-icon"
                  title="Peppol"
                />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="flex mt-2 justify-content-between">
        <Button
          icon={buttonClass}
          label={
            isDesktop
              ? intl.formatMessage({
                  id: DIALOG_SEARCH_BUTTON_LABEL,
                })
              : ""
          }
          onClick={() =>
            handleSearch({
              address: inputAddress,
              isInstitution: checkedIsInstitution,
            })
          }
        />

        <Button
          icon={"pi pi-undo"}
          label={
            isDesktop
              ? intl.formatMessage({
                  id: DIALOG_CLEAR_FILTER_LABEL,
                })
              : ""
          }
          onClick={() => {
            resetState();
          }}
        />
      </div>
    </div>
  );
};

export default injectIntl(AddressFilterLayout);
