import React, { useState, useEffect } from "react";
// primeng
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
// localization
import { injectIntl } from "react-intl";
// helpers
import {
  sendInvoiceToPeppol,
  dateToQueryString,
  addLocalPeppolLog,
} from "common/Helpers";

import { MESSAGE_SEVERITY, PEPPOL_STATES } from "assets/staticData/enums";

const DELAY_HIDE_LOADING_PEPPOL = 2000;

const PeppolView = (props) => {
  const { bill, toast } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [peppolState, setPeppolState] = useState(null);

  // transactionPeppolLogs changes when an invoice is being send.. so we need useEffect to capture this change :)
  useEffect(() => {
    if (bill?.transactionPeppolLogs?.length > 0) {
      bill.transactionPeppolLogs.sort((a, b) => {
        return new Date(b.peppolSendDate) - new Date(a.peppolSendDate);
      });

      setPeppolState(bill.transactionPeppolLogs[0]?.peppolState);
    }
  }, [bill]);

  const peppolId = bill?.invoiceAddress?.peppolParticipantId;

  const handleSendInvoiceToPeppol = async () => {
    let newStatus = null;

    if (peppolId && bill) {
      setIsFetching(true);
      try {
        // we need this to resend the invoice if invoice is already saved, we only resend if the invoice is already being successfully saved to befresh
        await sendInvoiceToPeppol(bill);
        newStatus = PEPPOL_STATES.SUCCESS;
      } catch (error) {
        newStatus = PEPPOL_STATES.FAILED;
        if (toast) {
          toast.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: error.message || "Unknown error occurred",
          });
        }
      } finally {
        setTimeout(() => {
          setIsFetching(false);
        }, DELAY_HIDE_LOADING_PEPPOL);

        setPeppolState(newStatus);
      }
    } else {
      toast.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: "PeppolId or bill is null.",
      });
    }
  };

  const generateHistoryLog = (logs) => {
    if (!logs || !logs.length) {
      return "";
    }

    return logs
      .map(
        (log) =>
          `${dateToQueryString(log?.peppolSendDate, true)}: ${
            log?.peppolState
          } ${
            log?.peppolRequestMessage ? " - " + log?.peppolRequestMessage : ""
          }`
      )
      .join("\n");
  };

  const PEPPOL_ICONS = {
    success: "pi pi-check",
    warning: "pi pi-exclamation-triangle",
    failed: "pi pi-times",
  };

  const getIconBasedOnPeppolState = (state) =>
    PEPPOL_ICONS[state] || PEPPOL_ICONS.warning;

  return (
    <>
      {peppolId &&
        bill?.transactionId > 0 &&
        bill?.transactionDetails?.length > 0 && (
          <div className="font-light peppol-status flex align-items-center justify-content-center gap-2">
            <Tooltip target=".peppol-badge" />
            <div className="peppol-status-img">
              <img
                src="assets/layout/images/peppol-icon-alone.png"
                alt="peppol icon"
                className="peppol-icon"
                title="peppol"
              />
              <div
                data-pr-tooltip={generateHistoryLog(
                  bill?.transactionPeppolLogs
                )}
                data-pr-position="bottom"
                className="peppol-badge"
                style={{ height: "24px" }}
              >
                <Badge value={bill?.transactionPeppolLogs?.length}></Badge>
              </div>
            </div>
            {isFetching && (
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px", width: "50px", top: "0px" }}
              ></ProgressBar>
            )}
            {!isFetching && (
              <>
                <div className="peppol-status-text">
                  <i className={getIconBasedOnPeppolState(peppolState)}></i>
                  {peppolState !== PEPPOL_STATES.SUCCESS && (
                    <Button
                      icon="pi pi-replay"
                      aria-label="Replay"
                      size="small"
                      onClick={handleSendInvoiceToPeppol}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
    </>
  );
};

export default injectIntl(PeppolView);
