/**
 * This component handles the bill export to peppol process.
 *
 * @version 1.0
 * @author [Dylan Ribeiro]
 */

//react
import React from "react";

//primeng
import "./Style.scss";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";

//utils
import { initLogger, sendInvoiceToPeppol, sendQuery } from "common/Helpers";
import { BACKGROUND_TASKS, QUERIES } from "assets/staticData/enums";

class PeppolExporterTask extends React.Component {
  //ENUMS
  DELAY_SHOW_END_MESSAGE = 2000;
  DELAY_CLOSE_COMPONENT = 3000;

  logger = initLogger("peppol_exporter_view");

  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      isFinished: false,
      peppolProceededBills: 0,
      totalPeppolBills: 0,
      activeInvoiceNumber: "",

      failedSendInvoices: [],
      successSendInvoices: [],
    };
  }

  componentDidMount() {
    this.logger.log("Peppol Exporter Task started... " + new Date());
    this.handleProceedBillsToPeppol();
  }

  componentWillUnmount() {
    this.logger.log("Peppol Exporter Task ended. " + new Date());
  }

  handleProceedBillsToPeppol = async () => {
    let fastShowEnding = false;
    try {
      this.setState({ isFetching: true });

      const response = await sendQuery(QUERIES.GET_PEPPOL_BILLS_TO_SEND, "GET");

      if (response && response.length > 0) {
        this.setState({ totalPeppolBills: response.length });
        this.setState({ isFetching: false });

        for (const bill of response) {
          await this.proceedPeppolBill(bill)
            .then(() => {
              this.setState((prevState) => ({
                peppolProceededBills: prevState.peppolProceededBills + 1,
                successSendInvoices: [
                  ...prevState.successSendInvoices,
                  bill?.invoiceNumber,
                ],
              }));
            })
            .catch((ex) => {
              this.logger.warn("Peppol invoice not created due to: " + ex);
              this.setState((prevState) => ({
                peppolProceededBills: prevState.peppolProceededBills + 1,
                failedSendInvoices: [
                  ...prevState.failedSendInvoices,
                  bill?.invoiceNumber,
                ],
              }));
            });
        }
      } else {
        // smooth UI switch
        await new Promise((resolve) =>
          setTimeout(resolve, this.DELAY_SHOW_END_MESSAGE)
        );
        fastShowEnding = true;
      }
    } catch (ex) {
      this.logger.error(
        "Could not fetch peppol failed/warned invoices due to: " + ex
      );
      fastShowEnding = true;
    } finally {
      this.setState({ isFetching: false });
      this.showSummary(fastShowEnding);
    }
  };

  proceedPeppolBill = async (bill) => {
    console.log(bill);
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ activeInvoiceNumber: bill?.invoiceNumber });
        await sendInvoiceToPeppol(bill);
        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  };

  showSummary = (fastShowEnding) => {
    setTimeout(
      () => {
        this.setState({ isFinished: true });
      },
      fastShowEnding === false ? this.DELAY_SHOW_END_MESSAGE : 0
    );
  };

  KillMyself = () => {
    this.props.killTask(BACKGROUND_TASKS.PeppolExporterTask);
  };

  render = () => {
    const {
      totalPeppolBills,
      peppolProceededBills,
      isFetching,
      activeInvoiceNumber,
      isFinished,
      failedSendInvoices,
      successSendInvoices,
    } = this.state;

    const progressPercentage = Math.ceil(
      (peppolProceededBills / totalPeppolBills) * 100
    );

    return (
      <div className="peppol-exporter-container">
        {isFetching && (
          <div className="loading">
            <img
              src="assets/layout/images/peppol-white-transparent.png"
              alt="peppol icon"
              className="peppol-icon"
              title="Peppol"
            />
            <i className="pi pi-spin pi-spinner loading-icon"></i>
          </div>
        )}

        {!isFetching && !isFinished && (
          <div className="processing">
            <div className="status">
              {peppolProceededBills} / {totalPeppolBills} ({activeInvoiceNumber}
              )
            </div>
            <div className="progress-bar">
              <ProgressBar
                value={progressPercentage}
                color="#03a8dd"
              ></ProgressBar>
            </div>
          </div>
        )}

        {isFinished && (
          <div className="finished">
            <div className="close-button-container">
              <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger custom_delete_button"
                onClick={() => this.KillMyself()}
              />
            </div>
            <div className="summary-container">
              <span className="checked">
                {successSendInvoices?.length}
                <i className="pi pi-check-circle checked-icon"></i>
              </span>
              <span className="failed">
                {failedSendInvoices?.length}
                <i className="pi pi-times-circle failed-icon"></i>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };
}

export default PeppolExporterTask;
